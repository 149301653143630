<template>
  <div class="quillText">
    <quill-editor ref="myQuillEditor" @click.native="handleEditAblequestion" v-model="innerValue" :options="quillOption"></quill-editor>
  </div>
</template>

<script>
import quillConfig from '@/utils/quill-config'

export default {
  props: {
    value: {
      type: String,
      default () {
        return ''
      }
    },
    object: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      qillInit: true,
      quillOption: quillConfig,
      innerValue: ''
    }
  },
  watch: {
    value: {
      handler (n, o) {
        if (n !== o) this.innerValue = this.value
      }
    },
    innerValue: {
      handler (n, o) {
        if (n !== o) this.$emit('update:value', n)
      }
    }
  },
  created () {
  },
  mounted () {
    this.$refs.myQuillEditor.quill.enable(false)
  },
  methods: {
    handleEditAblequestion () {
      if (this.qillInit) {
        this.$refs.myQuillEditor.quill.enable(true)
        this.$refs.myQuillEditor.quill.focus()
        this.qillInit = false
      }
    }
  }
}
</script>

<style lang="scss" scope>
.weekSelect {
  width: 100%;
}
</style>
