import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
import quillText from '@/components/quillText'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: false,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 12,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.cnName') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        rows: 4,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.descriptionCn') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        rows: 4
      }
    }, {
      span: 24,
      prop: 'bundle_guide',
      name: 'component',
      label: i18n.t('form.coupon.useGuideCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText
      }
    }, {
      span: 24,
      prop: 'bundle_guide_en',
      name: 'component',
      label: i18n.t('form.coupon.useGuideEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText
      }
    }, {
      span: 24,
      prop: 'bundle_policy',
      name: 'component',
      label: i18n.t('form.coupon.PolicyCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText
      }
    }, {
      span: 24,
      prop: 'bundle_policy_en',
      name: 'component',
      label: i18n.t('form.coupon.PolicyEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText
      }
    }, {
      span: 12,
      prop: 'bundle_value_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.bundleValue'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.coupon.bundleValue') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'price_f',
      name: 'input',
      type: 'number',
      value: 0,
      label: i18n.t('form.product.price'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'expiration_in_days',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.expirationInDays'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.coupon.expirationInDays') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100,
        imageName: 'remote_image_url'
      }
    }, {
      span: 24,
      prop: 'bundled_coupons_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.coupon.bundledCoupon'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'coupon_id',
        name: 'select',
        options: [],
        label: i18n.t('form.coupon.coupon'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          filterable: true,
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.coupon.coupon') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'quantity',
        name: 'input',
        type: 'number',
        label: i18n.t('form.coupon.quantity'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.coupon.quantity') }) }
          ]
        }
      }]
    }]
  }]
}
